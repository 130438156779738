<template>
  <DialogComponent
    ref="dialogComponent"
    :contentClass="'dialogSize-60'"
    :isDialog="true"
  >
    <template #header>
      <v-toolbar dark color="primaryBlue">
        <v-toolbar-title> {{ $t("newsupplier") }} </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn
          color="success"
          :loading="!isSuppliersLoaded"
          :disabled="!isSuppliersLoaded"
          type="submit"
          @click="saveNew"
        >
          {{ $t("save") }}
        </v-btn>
      </v-toolbar>
    </template>

    <template #content>
      <div class="mt-3">
        <v-input
          v-for="(error, i) in getSupplierErrors"
          :key="i"
          :error-messages="error"
          error
          class="errorMsgs"
        >
        </v-input>
      </div>

      <form @submit.prevent="saveNew">
        <v-row class="ma-0">
          <v-col cols="12" md="4">
            <GlobalAutoComplete
              v-if="getUser"
              :label="$t('branch')"
              :data="getUser.branches"
              v-model="branch_id"
              clearable
              :dataLoading="!isUserLoaded"
            />
          </v-col>

          <v-col cols="12" md="4">
            <BasicTextField
              v-model="name"
              :label="$t('name')"
              :error-messages="nameErrors"
              @input="$v.name.$touch()"
            />
          </v-col>

          <v-col cols="12" md="4">
            <BasicTextField
              v-model="email"
              :label="$t('email')"
              :error-messages="emailErrors"
              @input="$v.email.$touch()"
            />
          </v-col>

          <v-col cols="12" md="4">
            <BasicTextField
              v-model="tax_registeration"
              :label="$t('tax_registeration')"
            />
          </v-col>

          <v-col cols="12" md="4">
            <BasicTextField
              v-model="commercial_record"
              :label="$t('commercial_record')"
            />
          </v-col>

          <v-col cols="12" md="4">
            <BasicTextField v-model="address" :label="$t('address')" />
          </v-col>

          <v-col cols="12" md="4">
            <BasicTextField
              v-model="phone"
              :label="$t('phone')"
              type="number"
            />
          </v-col>
          <v-col cols="12" md="4" v-if="!fastCreate">
            <GlobalAutoComplete
              :label="$t('classifications')"
              :data="getClassifications"
              v-model="classification_ids"
              multiple
              clearable
            />
          </v-col>

          <v-col cols="12" md="4" v-if="!fastCreate">
            <GlobalAutoComplete
              :label="$t('states')"
              :data="getStates"
              v-model="state"
              return-object
              clearable
              :dataLoading="!isStatesLoaded"
              @change="city = null"
            />
          </v-col>

          <v-col cols="12" md="4" v-if="!fastCreate">
            <GlobalAutoComplete
              :label="$t('cities')"
              :data="getCities"
              v-model="city"
              return-object
              clearable
              :dataLoading="!isStatesLoaded"
            />
          </v-col>

          <v-col cols="12" md="4" v-if="!fastCreate">
            <v-checkbox
              v-model="iscash"
              outlined
              :label="$t('iscash')"
              dense
              hide-details="auto"
            ></v-checkbox>
          </v-col>

          <v-col cols="12" md="4" v-if="!fastCreate">
            <v-checkbox
              v-model="hidden"
              outlined
              :label="$t('hidden')"
              dense
              hide-details="auto"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-btn
          class="d-none"
          :loading="!isSuppliersLoaded"
          :disabled="!isSuppliersLoaded"
          type="submit"
        >
          {{ $t("save") }}
        </v-btn>
      </form>
    </template>
  </DialogComponent>
</template>

<script>
import { mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";
export default {
  props: [
    "isSuppliersLoaded",
    "getSupplierErrors",
    "getClassifications",
    "fastCreate",
    "getStates",
    "isStatesLoaded",
  ],
  validations() {
    return {
      // branch_id: { required },
      name: { required },
      email: { email },
    };
  },
  components: {},
  data() {
    return {
      branch_id: null,
      name: null,
      email: null,
      hidden: false,
      iscash: false,
      tax_registeration: null,
      commercial_record: null,
      address: null,
      phone: null,
      classification_ids: null,
      state: null,
      city: null,
    };
  },
  methods: {
    ...mapActions("Suppliers", ["createSupplier"]),
    openDialog() {
      this.clearErrors();
      this.$refs.dialogComponent?.openDialog().then((res) => {
        // if (this.getUser.branches.length == 1) {
        //   let branch = this.getUser.branches[0];
        //   this.branch_id = branch.id;
        // }
      });
    },
    clearErrors() {
      this.$v.$reset();
    },
    closeDialog() {
      this.$refs.dialogComponent?.closeDialog();
    },

    saveNew() {
      if (this.isSuppliersLoaded) {
        this.$v.$touch();
        if (!this.$v.$error) {
          this.createSupplier({
            name: this.name,
            hidden: this.hidden,
            iscash: this.iscash,
            email: this.email,
            tax_registeration: this.tax_registeration,
            commercial_record: this.commercial_record,
            address: this.address,
            phone: this.phone,
            branch_id: this.branch_id,
            classification_ids: this.classification_ids,
            state_id: this.state?.id,
            city_id: this.city?.id,
            fastCreate: this.fastCreate,
          }).then((res) => {
            this.$emit("updateClient", res);
          });
        }
      }
    },
  },
  computed: {
    getCities() {
      return this.getStates.flatMap((state) =>
        state.id === this.state?.id ? state.cities : []
      );
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push(this.$t("required.name"));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(this.$t("required.email"));
      return errors;
    },
    // branchIdErrors() {
    //   const errors = [];
    //   if (!this.$v.branch_id.$dirty) return errors;
    //   !this.$v.branch_id.required && errors.push(this.$t("required.branch"));
    //   return errors;
    // },
  },
};
</script>

<style></style>
